// Initialize main stores
export default defineNuxtPlugin(async () => {
  const authenticationStore = useAuthenticationStore()
  const { authUser } = storeToRefs(authenticationStore)
  const { listenAuth } = authenticationStore

  const enterpriseStore = useEnterpriseStore()
  const { getEnterprise, resetEnterprise } = enterpriseStore

  const authUserStore = useAuthUserStore()
  const { getAuthUser, resetAuthUser } = authUserStore

  const route = useRoute()

  const initStores = async () => {
    const enterpriseId = authUser.value?.claims?.enterpriseId

    if (!authUser.value || !enterpriseId) {
      return
    }

    const enterpriseData = await getEnterprise(enterpriseId)

    if (!enterpriseData) {
      return
    }

    const userId = authUser.value.uid

    await getAuthUser(userId)
  }

  // Initialize stores as quickly as possible
  await initStores()

  watch(authUser, async userInfo => {
    if (!userInfo) {
      return
    }

    const enterpriseId = userInfo.claims?.enterpriseId

    if (!enterpriseId) {
      return
    }

    await initStores()
  })

  // Update stores according to authentication state
  listenAuth(async user => {
    if (user) {
      await initStores()
      return
    }

    resetEnterprise()
    resetAuthUser()

    if (
      isSamePath(route, '/sign-in') ||
      isSamePath(route, '/support')
    ) {
      return
    }

    await navigateTo({
      path: '/sign-in',
      query: {
        from: route.path.length > 1 ? route.path : undefined
      }
    }, {
      replace: true
    })
  })
})
