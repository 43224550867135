import type { RouteLocationRaw, RouteLocationNormalizedGeneric } from '#vue-router'

const getRouteLocation = (path: string, from: RouteLocationNormalizedGeneric): RouteLocationRaw => ({
  path,
  query: {
    from: from.path.length > 1 ? from.path : undefined
  }
})

export default defineNuxtRouteMiddleware((to, from) => {
  const authenticationStore = useAuthenticationStore()
  const { isAuthenticated } = storeToRefs(authenticationStore)

  const authUserStore = useAuthUserStore()
  const { currentAuthUser } = storeToRefs(authUserStore)
  const authUserId = currentAuthUser.value.initial.id

  // Public page
  if (isSamePath(to, '/support')) {
    return
  }

  // User not authenticated
  if (
    !isAuthenticated.value &&
    !isSamePath(to, '/sign-in')
  ) {
    const routeLocation = getRouteLocation('/sign-in', from)
    return navigateTo(routeLocation)
  }

  // User not registered
  if (
    isAuthenticated.value &&
    !authUserId &&
    !isSamePath(to, '/sign-up')
  ) {
    const routeLocation = getRouteLocation('/sign-up', from)
    return navigateTo(routeLocation)
  }

  const isSamePaths = (
    isSamePath(to, '/sign-in') ||
    isSamePath(to, '/sign-up')
  )

  // User authenticated and registered
  if (
    isAuthenticated.value &&
    authUserId &&
    isSamePaths
  ) {
    const routeLocation = getRouteLocation('/playlists', from)
    return navigateTo(routeLocation)
  }
})
