
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexY7lLa8knJiUHTPBSBi6VdDwkzi71qwK2S_45hpNCoZwewMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/index.vue?macro=true";
import { default as indexGByeJylyVQmy_45PW1Zz203i33IJVHGS_cbjvby0xTqOQMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/users/index.vue?macro=true";
import { default as _91userId_93BO4kvnHh4_4XMhVzgKUUoYTo_45xN411sq3Yf7ZSUGqMcMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/users/[userId].vue?macro=true";
import { default as usersDSoDqZDAgS7g_fLVGtdsFBak00vzXJfpq_OAalPo8MUMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/users.vue?macro=true";
import { default as index9SEifaDhS6Jcz1fztEIYwdavL50CcbCyzZzN98s2rZMMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/assets/index.vue?macro=true";
import { default as assets1AcYq_45yo3yIRTPT0n0w1bL1eR_9EzhBeM00hBeDZcFcMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/assets.vue?macro=true";
import { default as indexvIYxBd2S83rCNyZ7FBLssx_BoDovyf5jaWvVwMlvlEMMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/account/index.vue?macro=true";
import { default as account_PFxd4xvw1Xf70Dn_45TTFuwcJMo_W6frOJOwW7yi3VCgMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/account.vue?macro=true";
import { default as indexMB11xvYuqQdrpyBer4wSgsEP6ulDdnuxL21aWMljOxgMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/screens/index.vue?macro=true";
import { default as _91screenId_93KYZFBTRx7pbJ4a8c3QIhZKQUVipF1buLkudJXn2fBpcMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/screens/[screenId].vue?macro=true";
import { default as screensKg58ao6F_vHNmO_45C3wNxXHgs6_HXQZZOvlVWwmuQWKAMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/screens.vue?macro=true";
import { default as sign_45inJFYOO1lFkKA8ugOIzlYmuXq4aPGu2cpjgxSBhZgLn_QMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/sign-in.vue?macro=true";
import { default as sign_45upDEAUn5Cq5unoAog_Weh_45uDnQ0C56Lm4WZIURsdDXoc8Meta } from "/home/bgrand/Documents/Projects/live-display/app/pages/sign-up.vue?macro=true";
import { default as supportb1hfQptS_JxG_45_45FMIqpLn9Ly1YS2w1W_Mzf8G0KfK3sMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/support.vue?macro=true";
import { default as indexUQkubTfiMbOKjgfrfWC40STKLKWfRspekONCYKhccGsMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/invoices/index.vue?macro=true";
import { default as invoices93UrUhZCzTUlf_nsI4fmHQKuEfaz0s9i5w6bF9ydPtQMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/invoices.vue?macro=true";
import { default as indexOT2SjqOXWwnlHW5NzSZZM8J6TNo0RnDAGyLp3T_w5fUMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/playlists/index.vue?macro=true";
import { default as indexe1IoKMEYMithb1s_0ERRIbk0QUzduApwctsVgQWoaHUMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId]/index.vue?macro=true";
import { default as _91pageId_93l1x_45XaXadFi_29oUCeYukTt9XNaB7LkeZpPuxAP0e_45MMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId]/[pageId].vue?macro=true";
import { default as _91playlistId_935G48_45wU_Zze_UyvCf7Lk88W4_45tZsWlAPCe9Yud510k0Meta } from "/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId].vue?macro=true";
import { default as playlistsMRFBbSUSjjY7c3tATncU91_45HfrS_YM28e1dJto65hIoMeta } from "/home/bgrand/Documents/Projects/live-display/app/pages/playlists.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexY7lLa8knJiUHTPBSBi6VdDwkzi71qwK2S_45hpNCoZwewMeta || {},
    redirect: "/playlists",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/index.vue")
  },
  {
    name: usersDSoDqZDAgS7g_fLVGtdsFBak00vzXJfpq_OAalPo8MUMeta?.name,
    path: "/users",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/users.vue"),
    children: [
  {
    name: "users",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/users/index.vue")
  },
  {
    name: "users-userId",
    path: ":userId()",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/users/[userId].vue")
  }
]
  },
  {
    name: assets1AcYq_45yo3yIRTPT0n0w1bL1eR_9EzhBeM00hBeDZcFcMeta?.name,
    path: "/assets",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/assets.vue"),
    children: [
  {
    name: "assets",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/assets/index.vue")
  }
]
  },
  {
    name: account_PFxd4xvw1Xf70Dn_45TTFuwcJMo_W6frOJOwW7yi3VCgMeta?.name,
    path: "/account",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/account.vue"),
    children: [
  {
    name: "account",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/account/index.vue")
  }
]
  },
  {
    name: screensKg58ao6F_vHNmO_45C3wNxXHgs6_HXQZZOvlVWwmuQWKAMeta?.name,
    path: "/screens",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/screens.vue"),
    children: [
  {
    name: "screens",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/screens/index.vue")
  },
  {
    name: "screens-screenId",
    path: ":screenId()",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/screens/[screenId].vue")
  }
]
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45inJFYOO1lFkKA8ugOIzlYmuXq4aPGu2cpjgxSBhZgLn_QMeta || {},
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/sign-in.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upDEAUn5Cq5unoAog_Weh_45uDnQ0C56Lm4WZIURsdDXoc8Meta || {},
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/sign-up.vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/support.vue")
  },
  {
    name: invoices93UrUhZCzTUlf_nsI4fmHQKuEfaz0s9i5w6bF9ydPtQMeta?.name,
    path: "/invoices",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/invoices.vue"),
    children: [
  {
    name: "invoices",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/invoices/index.vue")
  }
]
  },
  {
    name: playlistsMRFBbSUSjjY7c3tATncU91_45HfrS_YM28e1dJto65hIoMeta?.name,
    path: "/playlists",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/playlists.vue"),
    children: [
  {
    name: "playlists",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/playlists/index.vue")
  },
  {
    name: _91playlistId_935G48_45wU_Zze_UyvCf7Lk88W4_45tZsWlAPCe9Yud510k0Meta?.name,
    path: ":playlistId()",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId].vue"),
    children: [
  {
    name: "playlists-playlistId",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId]/index.vue")
  },
  {
    name: "playlists-playlistId-pageId",
    path: ":pageId()",
    component: () => import("/home/bgrand/Documents/Projects/live-display/app/pages/playlists/[playlistId]/[pageId].vue")
  }
]
  }
]
  }
]